import React from 'react';
import {ThemeProvider} from 'styled-components';
import {BuildYourOwn} from 'byolayout';
import {Light, Dark, Global} from 'branding_can-am-onroad';

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Global/>
      <ThemeProvider theme={{...Light, themes: {main: Light, alt: Dark}}}>
        <BuildYourOwn/>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
